.Page-header {
    position: relative;
    background-color: #282c34;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: calc(10px + 2vmin);
    min-width: 100vi;

    p {
        font-size: calc(10px + 2vmin);
        padding-left: 16px;
    }

    .Version-container {
        position: absolute;
        right: calc(4px + 0.5vmin);
        bottom: calc(4px + 0.5vmin);
        display: flex;
        flex-direction: column;
        justify-content: end;
        height: 100%;
        font-size: calc(8px + 1vmin);
    }
}