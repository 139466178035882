.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: fit-content;

  .App-hours-outer-container {
    display: flex;
    flex-direction: column;

    h3 {
      color: transparent;
    }

    .App-hours-inner-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: end;
      position: relative;
      margin-top: -8px;
      margin-bottom: -8px;

      .App-hours-label {
        margin: 0;
      }
    }
  }

  .Browser-view {
    min-width: 1500px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    padding: 16px;
    gap: 16px;

    .App-table {
      display: flex;
      flex-direction: row;
      flex: 4;
      border-radius: 16px;
      box-shadow: gray 4px 4px 12px;
      background-color: gold;
      overflow: hidden;
      gap: 1px;
      background-attachment: fixed;
      border: 2px solid gold;
    }

    .App-right-bar-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 16px;

      .App-settings {
        position: relative;
        display: flex !important;
        flex-direction: column;
        padding: 16px 16px 32px;
        border-radius: 16px;
        align-items: stretch !important;
        overflow: hidden;
        border: 2px solid gold;
        z-index: 1;
        background-color: #ffe86d;

        p {
          font-size: calc(10px + 1vmin);
          margin: 8px 0;
        }

        select {
          font-size: calc(8px + 1vmin);
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }

      .App-sidebar-wrapper {
        border-radius: 16px;
        box-shadow: gray 4px 4px 12px;
        overflow: hidden;

        .App-sidebar-container {
          display: flex !important;
          flex-direction: row !important;
          padding: 16px;
          border-radius: 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;

          p {
            font-size: 15px;
          }
        }

        .App-sidebar-warning-container {
          display: flex;
          flex-direction: column;
          padding: 16px;
          border-radius: 16px;
          align-items: start;

          .App-sidebar-warning-title {
            display: flex !important;
            align-self: stretch;
            flex-direction: row !important;
            justify-content: center;

            h3 {
              margin: 0 16px;
            }
          }

          .App-sidebar-warning-details {
            text-align: left;
            padding: 0 16px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .Mobile-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    scroll-behavior: unset;
    align-items: stretch;
    gap: 8px;
    padding: 12px 12px 48px;

    .Filters-container {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .Filter {
        flex: 1;
        background-color: #ffe86d;
        padding: 8px;
        border-radius: 100px;
        border: 2px solid gold;
        box-shadow: gray 2px 2px 4px;
        text-align: center;
        font-size: 16px;
      }
    }


    .Weekdays {
      display: flex;
      flex-direction: row;
      background-color: #ffe86d;
      padding: 2px;
      gap: 8px;
      border-radius: 100px;
      border: 2px solid gold;
      box-shadow: gray 2px 2px 4px;

      div {
        flex: 1;
        padding: 6px;
        border-radius: 100px;
      }

      .Chosen-weekday {
        background-color: gold;
        font-weight: bold;
      }
    }

    .Schedule-component {
      display: flex;
      flex-direction: row;
      flex: 1;
      gap: 4px;


      p {
        font-size: 12px;
      }

      .Schedule-column {
        position: relative;
        display: flex;
        background-color: #ffe032;
        flex: 1;
        border-radius: 16px;
        border: 2px solid gold;
        box-shadow: gray 2px 2px 4px;
        overflow: hidden;
        touch-action: pan-y;
      }
    }
  }
}

.Info-container {
  box-shadow: inset 0 0 16px rgba(0, 128, 255, 1); /* Inner glow effect */
  background-color: rgba(0, 128, 255, 0.1);
}

.Warning-container {
  box-shadow: inset 0 0 24px rgba(255, 200, 0, 1); /* Inner glow effect */
  background-color: rgba(255, 215, 0, 0.1);
}

.Error-container {
  box-shadow: inset 0 0 24px rgba(255, 0, 0, 1); /* Inner glow effect */
  background-color: rgba(255, 0, 0, 0.3);
}

/*.Tentrzeci-container {*/
/*    box-shadow: inset 0 0 24px rgba(0, 255, 0, 1); !* Inner glow effect *!*/
/*    background-color: rgba(0, 255, 0, 0.1);*/
/*}*/

.Shadow-wrapper {
  border-radius: 16px;
  box-shadow: gray 4px 4px 12px;
}

.Mobile-popup-container {
  background-color: white;
  border-radius: 16px;

  .swal2-title {
    color: black;
    font-size: 24px;
    padding-top: 32px;
  }

  .swal2-html-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .swal2-actions {
    margin-top: 0;
  }
}

p {
  font-size: 14px;
}