.App-table-column {
  flex: 1;
  display: flex;
  flex-direction: column;

  .App-table-column-title {
    position: relative;
    z-index: 1;
    background: inherit;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid gold;
  }

  .App-table-column-title:before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(10px);
    margin: -20px;
  }

  .App-table-column-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1px;
  }
}